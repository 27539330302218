blockquote {
	margin-left: 0;
	padding-left: 2em;
	padding-right: 2em;
	margin-top: 2em;
	margin-bottom: 2em;
	border-left: 2px solid var(--blockquote-border-colour);

	font-family: "Georgia", serif;
	font-style: italic;

	color: var(--blockquote-colour);

	opacity: 0.8;

	p {
		font-size: 1em;
	}

	p:first-of-type {
		margin-top: 0;
	}

	p:last-of-type {
		margin-bottom: 0;
	}

}
