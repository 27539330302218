@mixin no-select {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

@mixin transition($transition) {
	-webkit-transition: $transition;
	-o-transition: $transition;
	transition: $transition;
}
