a {
  color: var(--link-colour);
  text-decoration: underline;

  @include transition(0.25s color);

  &:hover {
    text-decoration: none;
  }

  &.back-button,
  &.contact-button {
    font-family: "Inter", "Helvetica", sans-serif;
    font-size: 1.0em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 600;

    text-decoration: none;
    color: var(-link-colour);

    &:hover {
      text-decoration: underline;
    }
  }
}
