article {
  font-size: 15px;
  line-height: 1.7;

  @media screen and (min-width: 767px) {
    font-size: 16px;
  }

  @media screen and (min-width: 1279px) {
    font-size: 17px;
    line-height: 1.7;
  }

  > h1 {
    display: inline-block;
    position: relative;
    margin-bottom: 0.1em;
    padding: 4px 0;

    font-family: "Inter", "Helvetica", sans-serif;
    font-weight: 600;
    font-size: 1.8em;
    line-height: 1.1em;

    color: var(--heading-colour);

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 1%;
      right: 1%;
      z-index: -1;
      transform: skewX(-15deg);

      background-color: var(--heading-background-colour);
    }

    @media screen and (min-width: 767px) {
      font-size: 1.9em;
    }

    @media screen and (min-width: 1279px) {
      font-size: 2.0em;
    }
  }

  > h2 {
    margin-top: 1em;

    font-family: "Inter", "Helvetica", sans-serif;
    font-size: 1.25em;
    font-weight: 300;

    @media screen and (min-width: 1279px) {
      font-size: 1.4em;
    }
  }

  > h3 {
    margin-top: 2em;
    margin-bottom: 0em;

    font-family: "Inter", "Helvetica", sans-serif;
    font-size: 0.75em;

    text-transform: uppercase;
    opacity: 0.9;
    letter-spacing: 0.05em;

    font-weight: 300;
  }

  > p {
    margin-top: 0.5em;
    margin-bottom: 2em;
  }

  > ul,
  > ol {
    margin-left: 15px;
    margin-bottom: 2em;
    padding-left: 0;
  }

  > ul {
    list-style-type: '–  ';
  }

  > ol {
    padding-left: 2em;

    li {
      padding-left: 10px;
    }
  }
}

.post-header,
.post-footer {
  padding-top: 1em;
  padding-bottom: 1em;

  @media screen and (min-width: 1279px) {
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.post-footer {
  margin-top: 3em;
}
