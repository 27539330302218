form {
  width: 75%;
  margin-top: 3em;

  input {
    font-family: inherit;
  }

  input[type="submit"] {
    appearance: none;;
    padding: 10px 15px;
    border: none;

    font-family: "Inter", "Helvetica", sans-serif;
    font-size: 0.9em;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 0.05em;

    color: white;
    background: var(--link-colour);

    cursor: pointer;

    border-radius: 2px;

    &:hover {
      text-decoration: none;
    }
  }

  input[type="email"],
  textarea {
    padding: 10px;
    border: 1px solid var(--border-colour);
    margin-bottom: 1em;

    font-family: inherit;
    font-size: inherit;

    border-radius: 3px;
  }

  textarea {
    width: 100%;
  }
}
