$bright-white: #FFFFFF;
$off-white: #F9F9F9;

$dark-black: #000000;
$off-black: #333333;

$highlighter-yellow: #F2E635;
$highlighter-pink: #FA2180;

:root {
  --text-colour: #{$off-black};
  --background-colour: #{$off-white};

  --text-select-colour: #{$bright-white};
  --text-select-background-colour: #{$highlighter-pink};

  --link-colour: #{$highlighter-pink};

  --heading-colour: #{$off-black};
  --heading-background-colour: #{$highlighter-yellow};

  --code-colour: #{$highlighter-pink};
  --code-background-colour: #{$bright-white};
  --code-border-colour: #{rgba( $highlighter-pink, 0.25 )};

  --blockquote-border-colour: #{$highlighter-pink};

  --border-colour: #CCCCCC;

  @media (prefers-color-scheme: dark) {
    --text-colour: #{$off-white};
    --background-colour: #{$off-black};

    --text-select-colour: #{$bright-white};
    --text-select-background-colour: #{$highlighter-pink};

    --link-colour: #{$highlighter-pink};

    --heading-colour: #{$off-black};
    --heading-background-colour: #{$highlighter-yellow};

    --code-colour: #{$highlighter-pink};
    --code-background-colour: #{$dark-black};
    --code-border-colour: #{rgba( $highlighter-pink, 0.25 )};

    --blockquote-border-colour: #{$highlighter-pink};

    --border-colour: #CCCCCC;
  }
}
