h1, h2, h3, h4, h5, h6,
header, footer, article,
a, p, span, code, pre, img,
hr, div, blockquote {
	box-sizing: border-box;
}

article,
footer,
header,
section {
	display: block;
}


html {
	-ms-text-size-adjust: 100%; /* 2 */
	-webkit-text-size-adjust: 100%; /* 2 */
}

a {
	background: transparent;
}

a:focus {
	outline: thin dotted;
}

a:active,
a:hover {
	outline: 0;
}
