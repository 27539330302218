.posts-list {
	padding: 0;
	margin: 0;

	line-height: 1.4;

	li {
		margin-bottom: 0;
		padding-bottom: 3em;
		list-style: none;
	}

	.post-date {
    margin: 0.75em 0;

    font-family: "Inter", "Helvetica", sans-serif;
    font-size: 0.6em;

    text-transform: uppercase;
    opacity: 0.9;
    letter-spacing: 0.05em;

    font-weight: 300;
	}

	.post-link {
    margin: 0.5em 0;

    font-family: "Inter", "Helvetica", sans-serif;
    font-size: 1.5em;
		text-decoration: none;
    font-weight: 600;

		color: var(--text-colour);

		&:hover {
			text-decoration: underline;
		}
	}

	.post-link-subtitle {
		margin: 0.20em 0;

		font-family: "Georgia", serif;
		font-size: 0.9em;
		font-style: italic;

		opacity: 0.7;
	}
}
