@import "./rouge-theme";

pre > code {
	display: block;
	width: 100%;
	padding: 2em;

	white-space: pre-wrap;
	background: white;
	overflow-x: auto;

	@media screen and (min-width: 767px) {
		padding: 3em;
  }
}

code {
	position: relative;
	display: inline-block;
	top: -1px;
	font-family: "Roboto Mono";
	padding: 4px 7px;
	margin: 2px 0;

	font-size: 0.8em;
	line-height: 1.2;

	color: var(--code-colour);
	background: var(--code-background-colour);
	border: 1px solid var(--code-border-colour);
	border-radius: 2px;;
}
