/* Generated by Pygments CSS Theme Builder - https://jwarby.github.io/jekyll-pygments-themes/builder.html */
/* Base Style */
.highlight pre {
  color: #545454;
  background-color: transparent;
}
/* Punctuation */
.highlight .p {
  color: #545454;
  background-color: transparent;
}
/* Error */
.highlight .err {
  color: #E31612;
  background-color: transparent;
}
/* Base Style */
.highlight .n {
  color: #fa2180;
  background-color: transparent;
}
/* Name Attribute */
.highlight .na {
  color: #fa2180;
  background-color: transparent;
}
/* Name Builtin */
.highlight .nb {
  color: #fa2180;
  background-color: #ffffff;
}
/* Name Class */
.highlight .nc {
  color: #fa2180;
  background-color: transparent;
}
/* Name Constant */
.highlight .no {
  color: #fa2180;
  background-color: #ffffff;
}
/* Name Decorator */
.highlight .nd {
  color: #fa2180;
  background-color: transparent;
}
/* Name Entity */
.highlight .ni {
  color: #fa2180;
  background-color: transparent;
}
/* Name Exception */
.highlight .ne {
  color: #fa2180;
  background-color: transparent;
}
/* Name Function */
.highlight .nf {
  color: #fa2180;
  background-color: transparent;
}
/* Name Label */
.highlight .nl {
  color: #fa2180;
  background-color: transparent;
}
/* Name Namespace */
.highlight .nn {
  color: #fa2180;
  background-color: transparent;
}
/* Name Other */
.highlight .nx {
  color: #fa2180;
  background-color: transparent;
}
/* Name Property */
.highlight .py {
  color: #fa2180;
  background-color: transparent;
}
/* Name Tag */
.highlight .nt {
  color: #545454;
  background-color: transparent;
}
/* Name Variable */
.highlight .nv {
  color: #fa2180;
  background-color: transparent;
}
/* Name Variable Class */
.highlight .vc {
  color: #fa2180;
  background-color: transparent;
}
/* Name Variable Global */
.highlight .vg {
  color: #fa2180;
  background-color: transparent;
}
/* Name Variable Instance */
.highlight .vi {
  color: #fa2180;
  background-color: #545454;
}
/* Name Builtin Pseudo */
.highlight .bp {
  color: #fa2180;
  background-color: transparent;
}
/* Base Style */
.highlight .g {
  color: #c614fd;
  background-color: transparent;
}
/*  */
.highlight .gd {
  color: #c614fd;
  background-color: transparent;
}
/* Base Style */
.highlight .o {
  color: #545454;
  background-color: transparent;
}
/* Operator Word */
.highlight .ow {
  color: #ffffff;
  background-color: transparent;
}
/* Base Style */
.highlight .c {
  color: transparent;
  background-color: transparent;
}
/* Comment Multiline */
.highlight .cm {
  color: transparent;
  background-color: transparent;
}
/* Comment Preproc */
.highlight .cp {
  color: transparent;
  background-color: transparent;
}
/* Comment Single */
.highlight .c1 {
  color: #b0b0b0;
  background-color: transparent;
}
/* Comment Special */
.highlight .cs {
  color: #b0b0b0;
  background-color: transparent;
}
/* Base Style */
.highlight .k {
  color: #545454;
  background-color: transparent;
}
/* Keyword Constant */
.highlight .kc {
  color: #545454;
  background-color: transparent;
}
/* Keyword Declaration */
.highlight .kd {
  color: #545454;
  background-color: transparent;
}
/* Keyword Namespace */
.highlight .kn {
  color: #545454;
  background-color: transparent;
}
/* Keyword Pseudo */
.highlight .kp {
  color: #545454;
  background-color: transparent;
}
/* Keyword Reserved */
.highlight .kr {
  color: #545454;
  background-color: transparent;
}
/* Keyword Type */
.highlight .kt {
  color: transparent;
  background-color: transparent;
}
/* Base Style */
.highlight .l {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal Date */
.highlight .ld {
  color: transparent;
  background-color: transparent;
}
/* Literal Number */
.highlight .m {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal Number Float */
.highlight .mf {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal Number Hex */
.highlight .mh {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal Number Integer */
.highlight .mi {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal Number Oct */
.highlight .mo {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal Number Integer Long */
.highlight .il {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String */
.highlight .s {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Backtick */
.highlight .sb {
  color: #e312d5;
  background-color: transparent;
}
/* Literal String Char */
.highlight .sc {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Doc */
.highlight .sd {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Double */
.highlight .s2 {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Escape */
.highlight .se {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Heredoc */
.highlight .sh {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Interpol */
.highlight .si {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Other */
.highlight .sx {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Regex */
.highlight .sr {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Single */
.highlight .s1 {
  color: #e312d5;
  background-color: #ffffff;
}
/* Literal String Symbol */
.highlight .ss {
  color: #e312d5;
  background-color: #ffffff;
}
/* Base Style */
.highlight .g {
  color: transparent;
  background-color: transparent;
}
/* Generic Deleted */
.highlight .gd {
  color: transparent;
  background-color: transparent;
}
/* Generic Emph */
.highlight .ge {
  color: transparent;
  background-color: transparent;
}
/* Generic Error */
.highlight .gr {
  color: transparent;
  background-color: transparent;
}
/* Generic Heading */
.highlight .gh {
  color: transparent;
  background-color: transparent;
}
/* Generic Inserted */
.highlight .gi {
  color: transparent;
  background-color: transparent;
}
/* Generic Output */
.highlight .go {
  color: transparent;
  background-color: transparent;
}
/* Generic Prompt */
.highlight .gp {
  color: transparent;
  background-color: transparent;
}
/* Generic Strong */
.highlight .gs {
  color: transparent;
  background-color: transparent;
}
/* Generic Subheading */
.highlight .gu {
  color: transparent;
  background-color: transparent;
}
/* Generic Traceback */
.highlight .gt {
  color: transparent;
  background-color: transparent;
}
/* Other */
.highlight .x {
  color: transparent;
  background-color: transparent;
}
/* Text Whitespace */
.highlight .w {
  color: transparent;
  background-color: transparent;
}
